<template>
  <div class="guide">
    <ul>
      <li
        v-for="(item, i) in guide_list"
        :key="i"
        @mouseover="showBox(i)"
        @mouseleave="hideBox(i)"
        @click="concatQQ(i)"
      >
        <img :src="item.logo" alt="" />
        <span>{{ item.name }}</span>
        <div
          class="code-box hide-box"
          v-if="i != 0"
          v-show="show_hide_index === i"
        >
          <img :src="info[item.code_key]" alt="" />
        </div>
        <div
          class="from-box hide-box"
          v-else
          v-show="show_hide_index === i"
        >
          <div class="box-title">
            <strong>预约顾问</strong>
            <a href="javascript:;" @click="resetForm">收起</a>
          </div>
          <div class="box-content">
            <a-form-model
              ref="ruleFormRef"
              :model="ruleForm"
              :rules="rules"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 16 }"
            >
              <a-form-model-item label="姓名" prop="name">
                <a-input v-model="ruleForm.name" />
              </a-form-model-item>
              <a-form-model-item label="联系电话" prop="phone">
                <a-input v-model="ruleForm.phone" />
              </a-form-model-item>
               <a-form-model-item label="地址" prop="address">
                <a-input v-model="ruleForm.address" />
              </a-form-model-item>
               <a-form-model-item label="公司名称" prop="company">
                <a-input v-model="ruleForm.company" />
              </a-form-model-item>
              <a-form-model-item label="备注" prop="remark">
                <a-input v-model="ruleForm.remark" type="textarea" />
              </a-form-model-item>
            </a-form-model>
          </div>
          <div class="btn-box">
            <a href="javascript:;" @click="onSubmit">提交</a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { getServiceList, makeAnAppointment } from '@/api/officialWebsite'

export default {
  data () {
    const validatePhone = (rule, value, callback) => {
      const telReg = /^[1][0-9]{10}$/
      if (!telReg.test(value)) {
        callback(new Error('请输入正确的联系电话'))
      } else {
        callback()
      }
    }
    return {
      info: {},
      show_hide_index: 100,
      guide_list: [
        {
          logo: require('@/assets/icon/3773@2x.png'),
          name: '预约顾问'
        },
        {
          logo: require('@/assets/icon/3773_1@2x.png'),
          name: '微信客服',
          code_key: 'wx_qr_code'
        }
        // {
        //   logo: require('@/assets/icon/3773_2@2x.png'),
        //   name: 'QQ客服',
        //   code_key: 'qq_qr_code'
        // }
      ],
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { validator: validatePhone, trigger: 'change' }
        ]
      },
      ruleForm: {
        name: '',
        phone: '',
        company: '',
        address: '',
        remark: ''
      }
    }
  },
  computed: {},
  created () {
    this.getService()
  },
  methods: {
    onSubmit () {
      this.$refs.ruleFormRef[0].validate(valid => {
        if (valid) {
          const { name, phone, address, company, remark } = this.ruleForm

          makeAnAppointment({
            name,
            phone,
            address,
            company,
            remark
          })
            .then((res) => {
              this.$message.success('预约成功')
            })
            .catch((error) => {
              console.error(error)
            })
        }
      })
    },
    resetForm () {
      this.hideBox()
      this.$refs.ruleFormRef[0].resetFields()
    },
    getService () {
      getServiceList()
        .then((data) => {
          this.info = data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    showBox (index) {
      this.show_hide_index = index
    },
    hideBox (index) {
      if (index !== 0) {
        this.show_hide_index = 100
        this.resetForm()
      }
    },
    concatQQ (index) {
      if (index === 2) {
        var tempwindow = window.open('_blank')
        tempwindow.location =
          'http://wpa.qq.com/msgrd?v=3&uin=' +
          this.info.qq_nums +
          '&site=qq&menu=yes'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.guide {
  width: 60px;
  position: fixed;
  right: 8px;
  bottom: 160px;
  z-index: 10;
  background: #00a0ac;
  border-radius: 2px 0px 0px 0px;
  ul {
    padding: 0 5px;
  }
  li {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    flex-wrap: wrap;
    padding-bottom: 5px;
    position: relative;
    cursor: pointer;
    img {
      width: 32px;
      height: 32px;
      display: block;
      margin: 0 auto;
    }
    span {
      font-size: 11px;
      color: #fff;
      flex: 0 0 100%;
      text-align: center;
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      opacity: 0.59;
      background: #ffffff;
      position: absolute;
      bottom: 0;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    .hide-box {
      position: absolute;
      left: -170px;
      top: 50%;
      transform: translateY(-50%);
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: -18px;
        transform: translateY(-50%);
        border: 9px solid #fff;
        border-color: transparent transparent transparent #fff;
      }
      &.code-box {
        width: 150px;
        height: 150px;
        box-shadow: 0px 0px 30px 0px rgba(85, 85, 85, 0.4);
        background: #fff;
        padding: 10px;
        img {
          width: 131px;
          height: 131px;
        }
      }
      &.from-box {
        width: 291px;
        background: #fff;
        padding: 12px 23px;
        left: -311px;
        .box-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #333;
          font-size: 16px;
          padding-bottom: 13px;
          border-bottom: 2px solid #00a0ac;
        }
        .btn-box {
          a {
            width: 100%;
            height: 32px;
            background: #00a0ac;
            border-radius: 2px;
            line-height: 32px;
            font-size: 14px;
            color: #fff;
            display: block;
            text-align: center;
          }
        }
        .box-content {
          padding: 16px 0 13px 0;
          .el-form-item {
            margin-bottom: 16px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
// 576px以下
@media screen and (max-width: 576px) {
  .guide {
    bottom: 100px;
  }
}
</style>
